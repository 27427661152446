import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import Layout from '../../../components/layout'
import SEO from "../../../components/seo"
// import { Helmet } from "react-helmet"
import SplitPane, { Pane } from 'react-split-pane'
import TwoColumn from "../../../components/two-column"
import Columnar from '../../../components/columnar'
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from '../../../components/tabs';

const ProductsPlansCycles = () => {
  const [imageSlidingWidth, setImageSlidingWidth] = useState();

  const handleResize = () => {
    setImageSlidingWidth(document.querySelector('.img-wrapper').offsetWidth)
    console.log("resize")
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener("resize", handleResize);
  }, [])

  const solidFoundations = {
    title: "Get your subscription business started with solid foundations.",
    description: [
      {
        text: "Not just plans - Products, Plans and Cycles help to keep your business organised and running well with advanced pricing modals so that you can charge customers exactly what you need to. It's incredibly quick and insanely powerful"
      }
    ],
    buttons: [
      {
        isExternal: true,
        url: 'https://app.billsby.com/registration',
        buttonColor: 'orange',
        buttonText: 'Sign up for free'
      },
      {
        scheduleButton: true,
        buttonColor: 'black',
      }
    ],
    sectionDevelopers: true,
    image: require('../../../images/ppc-header.svg'),
    svg: true,
    imageName: 'ppc header',
    backgroundColor: '#F3F5FB',
    textColor: '#252422',
    imagePosition: 'right',
    sectionFeaturetag: true,
    // featureTagButton: true,
    tags: [
      {
        class: 'adoric_video_products',
        text: 'Products, Plans and Cycles'
      }
    ]
  }

  const columnarContent = [
    {
      title: "With legacy billing solutions, everything is a plan.",
      list: [
        {
          title: 'Each of your plans is completely discrete',
          description: "So if you have two products each with three tiers of service (Bronze, Silver and Gold) and each of the tiers has a monthly, quarterly and annual variant you've already got 18 different plans. And because they're completely discrete, there's no business logic about moving between them or reporting on them.",
        },
        {
          title: 'New plans mean development changes',
          description: "Because there's no relational data to explain how each plan relates to the other, when you want to add new plans or cycles, your developers will need to complete updates and add details to your abstraction layer so that everything still works as you'd expect. That means delays and inflexibility for your business.",
        }
      ]
    },
    {
      title: "With Billsby - Products, Plans and Cycles keep things in order.",
      list: [
        {
          title: 'Product > Plan > Cycle',
          description: "This simple hierarchy makes your business much easier to manage. Create new billing cycle lengths without having to setup brand new plans, turn on and off prices with ease and know that customers can swap cycles and the correct pricing and pro-rating will always be applied.",
        },
        {
          title: 'Structured and clear API',
          description: "Because we pass the hierarchy through into the API, your developers will be able to produce pricing pages and marketing sites that dynamically update as your prices and plan options do - with no need to add an abstraction layer to figure out what all those disparate plan IDs actually mean.",
        },
      ]
    }
  ]

  const noLimitContent = {
    title: "No limit to your ambition with unlimited plans.",
    description: [
      {
        text: 'With Billsby you can create unlimited products, plans and cycles and then manage them with unlimited team members before applying unlimited and discounts and coupons and selling them in unlimited countries using unlimited currencies with unlimited tax profiles.'
      },
      {
        text: 'All with no monthly fees.'
      }
    ],
    textColor: '#fff',
    backgroundColor: '#243f5c',
    backgroundImage: require('../../../images/product-no-limit@2x.png'),
    backgroundPosition: 'right',
    image: 'product-no-limit.png',
    imageName: 'No Limit',
    imagePosition: 'full-right',
    sectionProDevelopmentBanner: true,
  }

  const pricingModels = {
    title: 'All the pricing models you need',
    description: <>Having the flexibility to price the way you want to is really important for your business. That's why we support all the pricing models your business might need - and you can use these for your base plan, <Link to="/" className="custom-link">add-ons and allowances</Link> in any combination.</>,
    tabs: [
      {
        tabTitle: 'Flat Fee',
        image: require('../../../images/flatfee.svg'),
        col1: [
          {
            heading: "The simplest of the pricing models",
            description: <>Charge the same flat fee to everyone who takes your subscription - with your choice of cycles. Create monthly and annual cycles to offer discounts to your customers and enforce minimum terms with our <Link to="/" className="custom-link">advanced billing options</Link>.</>
          },
          {
            heading: "We'll handle the term switches",
            description: "Customers can switch between cycles at any time - like from the monthly to the annual version of your plan, and we'll automatically handle switching them at the end of their pre-paid term."
          },
          {
            heading: "Fixed or flexible billing dates",
            description: "It's up to you when you charge customers - flexible billing dates let you charge on the anniversary of sign-up so revenue flows through the month, or fixed dates give you one big payments day - we can calculate the pro-rating."
          },
        ],
        mySubscription: [
          {
            subscription: "$10 per month"
          },
          {
            subscription: "$100 per year"
          }
        ]
      },
      {
        tabTitle: 'Per Unit',
        image: require('../../../images/perunit.svg'),
        col1: [
          {
            heading: "The pricing model that lets the customer decide",
            description: "Empower your customers by letting them choose how many units of your plan they would like to purchase upfront, and charge them based on the total price of their units. For example, if you operate a fitness class, you might charge $20 per session, and allow users to choose upfront that they want a plan with 10 sessions."
          },
          {
            heading: "We'll handle the term switches",
            description: "Customers can switch between cycles at any time - like from the monthly to the annual version of your plan, and we'll automatically handle switching them at the end of their pre-paid term."
          },
          {
            heading: "Fixed or flexible billing dates",
            description: "It's up to you when you charge customers - flexible billing dates let you charge on the anniversary of sign-up so revenue flows through the month, or fixed dates give you one big payments day - we can calculate the pro-rating."
          },
        ],
        mySubscription: [
          {
            subscription: "$20 per unit for 10 units, per month"
          },
          {
            subscription: "$15 per unit for 120 units, per year"
          }
        ]
      },
      {
        tabTitle: 'Volume',
        image: require('../../../images/ranged.svg'),
        col1: [
          {
            heading: "The pricing model that discounts automatically",
            description: "The more units your customer subscribes to, the cheaper the units get! With volume pricing, customers still choose how many units of your plan they would like to subscribe to, but this time you can offer quantity discounts based on how many units they select in total. So, for example saying it’s $5 per extra device for up to two more devices, but $3 per extra device for more than two devices."
          },
          {
            heading: "We'll handle the term switches",
            description: "Customers can switch between cycles at any time - like from the monthly to the annual version of your plan, and we'll automatically handle switching them at the end of their pre-paid term."
          },
          {
            heading: "Fixed or flexible billing dates",
            description: "It's up to you when you charge customers - flexible billing dates let you charge on the anniversary of sign-up so revenue flows through the month, or fixed dates give you one big payments day - we can calculate the pro-rating."
          },
        ],
        mySubscription: [
          {
            subscription: "$5 per unit for two units, per month"
          },
          {
            subscription: "$3 per unit for 24 units, per year"
          }
        ]
      },
      {
        tabTitle: 'Tiered',
        image: require('../../../images/tiered.svg'),
        col1: [
          {
            heading: "Discount with your pricing model, but don't be left out of pocket",
            description: "Give your customers a discount without losing out yourself! With tiered pricing, customers can still choose how many units of your plan they would like to subscribe to and receive quantity based discounts, but this time the discounts can be varied based on the tier in which the unit falls.So, for example, saying its $5 per extra device for up to two devices, but $3 per extra device for the 3rd device and above."
          },
          {
            heading: "We'll handle the term switches",
            description: "Customers can switch between cycles at any time - like from the monthly to the annual version of your plan, and we'll automatically handle switching them at the end of their pre-paid term."
          },
          {
            heading: "Fixed or flexible billing dates",
            description: "It's up to you when you charge customers - flexible billing dates let you charge on the anniversary of sign-up so revenue flows through the month, or fixed dates give you one big payments day - we can calculate the pro-rating."
          },
        ],
        mySubscription: [
          {
            subscription: "$5 per unit for two units then $3 for the third unit, per month"
          },
          {
            subscription: "$5 per unit for 50 units then $3 per unit for the next 50 units, per year"
          }
        ]
      },
      {
        tabTitle: 'Ranged',
        image: require('../../../images/volume.svg'),
        col1: [
          {
            heading: "Flat fee and uni based pricing all in one",
            description: "Let your customers choose how many units of your plan they want, but charge them a flat rate based on which range their units fall into. For example, you might charge $10 for up to two users, and $15 for three or more users."
          },
          {
            heading: "We'll handle the term switches",
            description: "Customers can switch between cycles at any time - like from the monthly to the annual version of your plan, and we'll automatically handle switching them at the end of their pre-paid term."
          },
          {
            heading: "Fixed or flexible billing dates",
            description: "It's up to you when you charge customers - flexible billing dates let you charge on the anniversary of sign-up so revenue flows through the month, or fixed dates give you one big payments day - we can calculate the pro-rating."
          },
        ],
        mySubscription: [
          {
            subscription: "$10 for two units, per month"
          },
          {
            subscription: "$150 for three users, per year"
          }
        ]
      }
    ]
  }

  const hiddenPlans = {
    title: <><span>Hidden plans</span> give you even more billing flexibility.</>,
    description: [
      {
        text: "Create hidden plans that you can offer to certain customers or hold back for your customer service team. They can be triggered through the API, our JavaScript checkout or in the control panel."
      },
      {
        text: "Use them to create exclusive plans for certain groups, run promotions and offers, handle edge cases or grandfather existing customers into legacy pricing plans whilst making price changes for new customers."
      }
    ],
    sectionProDevelopmentBanner: true,
    backgroundColor: '#f3f5fb',
    textColor: '#252422',
    backgroundImage: require('../../../images/img-hidden-plans@2x.png'),
    backgroundPosition: 'right',
    image: 'img-hidden-plans.png',
    imageName: 'pro-development image',
    imagePosition: 'full-right',
    sectionReverse: true
  }

  return(
    <Layout className="products-plans-cycles">
      <SEO 
        title="Products, plans and cycles | Billsby | Powerful, customizable subscription billing software" 
        description="Products, plans and cycles make Billsby the subscription billing and recurring payments software that keeps your plans organized and stops chaos and disorganization." 
        url="https://www.billsby.com/product/creating/products-plans-cycles"
      />

      <TwoColumn content={solidFoundations} />

      <div className="section-image-slide">
        <div className="container">
          <h2 className="heading-1">Once you've seen Products, Plans and Cycles you'll wonder why no other billing platform does it</h2>
          <p className="subheading-1">Building the strongest possible foundation for your business means being prepared for growth and expansion - which means keeping all of your available subscription options organised. With Billsby's unique plan hierarchy, stay in control.</p>
          <div className="img-wrapper">
            <SplitPane split="vertical" minSize={1} maxSize={-15} 
              defaultSize={(imageSlidingWidth/2)}
            >
              <div className="img-holder">
                <div className="slider-wing">
                  <div className="img-frame">
                    <img src={require('../../../images/ppc-slider-left.svg')} alt="" />
                  </div>
                </div>
              </div>
              <div className="img-holder">
                <div className="slider-wing">
                  <div className="img-frame">
                    <img src={require('../../../images/ppc-slider-right.svg')} alt="" />
                  </div>
                </div>
              </div>
            </SplitPane>
          </div>
        </div>
      </div>
    
      <Columnar content={columnarContent} />

      <TwoColumn content={noLimitContent} />

      <div className="section-pricing-models">
        <div className="container">
          <h2 className="heading-1">{pricingModels.title}</h2>
          <p className="subheading-1">{pricingModels.description}</p>
          <Tabs initialValue={0}>
            <TabList>
              {pricingModels.tabs.map((item, i) => {
                return (
                  <Tab name={i} key={i}>
                    <div className="image-wrapper">
                      <img src={item.image} className="image" alt={item.tabTitle} />
                    </div>
                    <h3 className="tab-title">{item.tabTitle}</h3>
                  </Tab>
                )
              })}
            </TabList>
          
            {
              pricingModels.tabs.map((item, i) => {
                return (
                  <TabPanel name={i} key={i}>
                    <div className="tab-content">
                      <div className="text-col">
                        {item.col1.map((innerItem, index) => (
                          <div className="paragraph">
                            <h6 className="heading">{innerItem.heading}</h6>
                            <p className="description">{innerItem.description}</p>
                          </div>
                        ))}
                      </div>
                      <div className="img-col">
                        <div className="my-subscription-holder">
                          <h6 className="subscription-heading">My Subscription</h6>
                          {
                            item.mySubscription.map((subscriptionItem, i) => (
                              <p className="subscription-details">
                                {subscriptionItem.subscription}
                              </p>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                )
              })
            }
          </Tabs>
        </div>
      </div>

      <TwoColumn content={hiddenPlans} />
      
    </Layout>
  )
}

export default ProductsPlansCycles